import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import * as API from 'src/apis';
import { awsRum } from 'src/utils/rum';

type ErrorCode =
  | 'bad_request'
  | 'DATETIME_IS_FUTURE'
  | 'not_found'
  | 'permission_denied'
  | 'emergency_maintenance'
  | 'unknown_error';

type FormValues = {
  title: string;
  beginAt: string;
  endAt: string;
  gatewayIds: string[];
};

type Response = void;

export type Result = {
  mutate: (values: FormValues) => Promise<Response>;
  reset: () => void;
  data?: Response;
} & (
  | { status: 'hasError'; errorCode: ErrorCode }
  | { status: 'loading' }
  | { status: 'idle' }
  | { status: 'succeeded' }
);

export function usePostWorkspacesReports(
  workspace: API.Workspace,
  props: { nuetralizeResults: () => void; onSuccess: () => void },
): Result {
  const { getAccessTokenSilently } = useAuth0();

  const workspaceId = workspace.workspaceId;

  const mutation = useMutation<Response, API.Error, FormValues>({
    mutationFn: async ({ title, beginAt, endAt, gatewayIds }) => {
      const token = await getAccessTokenSilently();
      const response = await API.postWorkspacesReports({
        workspaceId,
        title,
        beginAt,
        endAt,
        gatewayIds,
        token,
      });

      return response;
    },
    onSuccess: props.onSuccess,
  });

  const responseBase = {
    mutate: async (values: FormValues) => {
      props.nuetralizeResults();
      const result = await mutation.mutateAsync(values);

      return result;
    },
    reset: mutation.reset,
    data: mutation.data,
  };

  if (mutation.isIdle) {
    return {
      ...responseBase,
      status: 'idle',
    };
  }
  // Handle loading and no data states
  if (mutation.isPending) {
    return {
      ...responseBase,
      status: 'loading',
    };
  }

  if (mutation.isError) {
    const error = mutation.error;

    switch (error.code) {
      case 'bad_request':
        switch (error.errorCode) {
          case 'DATETIME_IS_FUTURE':
            return {
              ...responseBase,
              status: 'hasError',
              errorCode: error.errorCode,
            };
          default:
            return {
              ...responseBase,
              status: 'hasError',
              errorCode: 'bad_request',
            };
        }
      case 'permission_denied':
      case 'emergency_maintenance':
      case 'not_found':
        return {
          ...responseBase,
          status: 'hasError',
          errorCode: error.code,
        };
      case 'unknown_error':
      default:
        awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
        return {
          ...responseBase,
          status: 'hasError',
          errorCode: 'unknown_error',
        };
    }
  }

  return {
    ...responseBase,
    status: 'succeeded',
  };
}
