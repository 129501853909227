import { DateTime } from 'luxon';

const ISO_DATE_FORMAT = 'yyyy-MM-dd';
const SLASH_DATE_FORMAT = 'yyyy/M/d';
const SLASH_HH_MM_DATE_FORMAT = 'yyyy/M/d HH:mm';

export function toYmd(date: DateTime): string {
  return date.toFormat(ISO_DATE_FORMAT);
}

export function toYmdSlash(date: DateTime): string {
  return date.toFormat(SLASH_DATE_FORMAT);
}

export function toYmdSlashHm(date: DateTime): string {
  return date.toFormat(SLASH_HH_MM_DATE_FORMAT);
}

export function fromUnixMs(unixMs: number): DateTime {
  return DateTime.fromMillis(unixMs).setZone('Asia/Tokyo');
}
