import { Box } from '@mui/material';
import useSmallScreen from 'src/domains/root/hooks/use-small-screen';
import { BaseMainContents } from 'src/domains/root/commons/layout/MainContents';
import ReportListContainer from '../features/reports/ReportListContainer';

export default function Reports() {
  const isSmallScreen = useSmallScreen();

  return (
    <BaseMainContents showTopButton={!isSmallScreen}>
      <Box display="flex" flexDirection="column" rowGap={3.5}>
        <ReportListContainer />
      </Box>
    </BaseMainContents>
  );
}
