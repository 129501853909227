import { AxiosError, isAxiosError } from 'src/utils/api';
import { awsRum } from '../utils/rum';
import type { NPErrorCode } from './types';

export type Error = { errorCode?: string } & (
  | { code: 'np_error'; npErrorCodes: NPErrorCode[] }
  | { code: 'bad_request' }
  | { code: 'permission_denied' }
  | { code: 'not_found' }
  | { code: 'conflict' }
  | { code: 'emergency_maintenance' }
  | { code: 'unknown_error' }
);

export function handleError(error: unknown): Error | AxiosError {
  if (!isAxiosError(error)) {
    awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
    return { code: 'unknown_error' };
  }
  if (error.response) {
    const { status, data } = error.response;
    // npErrorCode: npかけ払いのエラーコード
    const { errorCode, npErrorCode } = data ?? {};
    switch (status) {
      case 400:
        switch (errorCode) {
          case 'NP_API_ERROR':
            return { code: 'np_error', npErrorCodes: npErrorCode };
          default:
            return { code: 'bad_request', errorCode };
        }
      case 403:
        return { code: 'permission_denied', errorCode };
      case 404:
        return { code: 'not_found', errorCode };
      case 409:
        return { code: 'conflict' };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return { code: 'emergency_maintenance' };
        }
    }
    awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
    return error;
  }
  awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
  return { code: 'unknown_error' };
}

/**
 * 既存のエラー上で追加処理をしたい時にtype guardとして追加います
 * @example
 *  } catch (error) {
    if (API.isApiError<UsersMeErrorCode>(error)) {
      switch (error.code) {
        case 'emergency_maintenance':
          return thunkAPI.rejectWithValue({
            code: 'emergency_maintenance',
            noticeable: false,
            recoverable: false,
          });
        default:
          return thunkAPI.rejectWithValue({
            code: 'unknown_error',
            noticeable: false,
            recoverable: false,
          });
      }
    }
 **/
export function isApiError<T>(error: unknown): error is { code: T } {
  return typeof error === 'object' && error !== null && 'code' in error;
}

export function getErrorCode<T>(
  errorCodeMap: Record<string, T | 'unknown_error'>,
  error: Error,
): T | 'unknown_error' {
  const errorCode: T | 'unknown_error' =
    (error.errorCode ? errorCodeMap[error.errorCode] : undefined) ||
    (error.code ? errorCodeMap[error.code] : 'unknown_error');

  if (errorCode === 'unknown_error') {
    awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
  }

  return errorCode;
}
