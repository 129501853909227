export function downloadFile({ fileURL }: { fileURL: string }) {
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = fileURL;

  // fileNameはurlのheaderからブラウザが取得
  tempLink.setAttribute('download', '');

  // download attributeがない場合のサーポット、ほぼないが念の為
  // @see https://caniuse.com/download
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();

  document.body.removeChild(tempLink);
}
