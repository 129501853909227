import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Link,
} from '@mui/material';
import useSmallScreen from 'src/domains/root/hooks/use-small-screen';
import TextLink from './TextLink';

interface InvoiceServiceDescriptionProps {
  size?: 'small' | 'medium';
  showPrice?: boolean;
}

export default function InvoiceServiceDescription(
  props: InvoiceServiceDescriptionProps,
) {
  const { size = 'medium', showPrice = false } = props;

  const isSmall = size === 'small';
  const isSmallScreen = useSmallScreen();

  return (
    <Box>
      <Box
        py={isSmall ? 1 : 2}
        maxWidth={isSmall ? '100%' : 400}
        lineHeight={0}
      >
        <Link
          href="https://np-kakebarai.com/buy/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ display: 'inline-block', lineHeight: 0 }}
        >
          <img
            src="/assets/npkakebarai/npkakebarai-banner.png"
            width={isSmall ? 468 : '100%'}
            height={isSmall ? 78 : 66}
            alt="npkakebarai-banner"
            style={{ objectFit: 'contain', maxWidth: '100%' }}
          />
        </Link>
      </Box>

      <Typography fontSize={isSmall ? 12 : 16}>
        法人/個人事業主を対象とした掛け払いサービスです。（月末締め翌月末請求書払い）
        <br />
        詳細はバナーをクリックしてご確認ください。
      </Typography>

      <Box
        pt={2}
        fontSize={isSmall ? 12 : 16}
        sx={{
          '& td': {
            display: {
              xs: 'block',
              sm: 'table-cell',
            },
            py: 1,
            pl: 1,
            minWidth: 160,
            border: '1px solid #BEBEBE',
            verticalAlign: 'top',

            '*': {
              fontSize: isSmall ? 12 : 16,
            },
          },
        }}
      >
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ backgroundColor: '#F3F2F2' }}>
                  <Typography>利用可能なお客様</Typography>
                </TableCell>
                <TableCell>
                  <Typography>法人/個人事業主のお客様</Typography>
                </TableCell>
              </TableRow>
              {showPrice && (
                <TableRow>
                  <TableCell sx={{ backgroundColor: '#F3F2F2' }}>
                    <Typography>CABIoT年間利用料（1年）</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      税込
                      <Typography
                        fontSize="1.5rem !important"
                        fontWeight={700}
                        component="span"
                      >
                        28,000
                      </Typography>
                      円{isSmallScreen && <br />}
                      <Typography component="span" ml={{ xs: -1, sm: 0 }}>
                        （内消費税10％：2,545円）
                      </Typography>
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell sx={{ backgroundColor: '#F3F2F2' }}>
                  <Typography>手数料</Typography>
                </TableCell>
                <TableCell>
                  <Typography>無料</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ backgroundColor: '#F3F2F2' }}>
                  <Typography>締め日</Typography>
                </TableCell>
                <TableCell>
                  <Typography>月末</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ backgroundColor: '#F3F2F2' }}>
                  <Typography>請求書発行日</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    ご購入の翌月第4営業日（株式会社ネットプロテクションズから発行）
                    <br />
                    ※請求書払いのご注文には、同社の提供するNP掛け払いサービスが適用され、ご注文と同時にご注文の商品の代金債権を譲渡します。
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ backgroundColor: '#F3F2F2' }}>
                  <Typography>支払い期限日</Typography>
                </TableCell>
                <TableCell>
                  <Typography>翌月末払い</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ backgroundColor: '#F3F2F2' }}>
                  <Typography>振込先・振込手数料</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    請求書に記載の口座へお振り込みください。
                    <br />
                    ※銀行振込の際の振込手数料はお客様にてご負担ください。
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ backgroundColor: '#F3F2F2' }}>
                  <Typography>留意事項</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    「
                    <TextLink href="https://np-kakebarai.com/buy/terms/">
                      NP掛け払い利用規約及び同社のプライバシーポリシー
                    </TextLink>
                    」に同意して請求書払いをご選択ください。
                    <br />
                    与信枠は、個別に設定させていただきます。
                    <br />
                    同月内に複数のCABIoTパックの契約を更新した場合、合計金額を一つの請求書で請求します。
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
