import { BaseMainContents } from 'src/domains/root/commons/layout/MainContents';
import ReportsMakeNewContainer from '../features/reports/ReportsMakeNewContainer';

export default function ReportsNew() {
  return (
    <BaseMainContents>
      <ReportsMakeNewContainer />
    </BaseMainContents>
  );
}
