import { useAuth0 } from '@auth0/auth0-react';
import { MutationStatus, useMutation } from '@tanstack/react-query';
import { useRef } from 'react';
import * as API from 'src/apis';

type ErrorCode =
  | 'bad_request'
  | 'permission_denied'
  | 'NOT_JOINED_WORKSPACE'
  | 'not_found'
  | 'NOT_FOUND_REPORT'
  | 'conflict'
  | 'emergency_maintenance'
  | 'unknown_error';

const mapErrorCode = (error: API.Error | null): ErrorCode => {
  if (!error) {
    return 'unknown_error';
  }

  const errorCodeMap: Record<string, ErrorCode> = {
    NOT_JOINED_WORKSPACE: 'NOT_JOINED_WORKSPACE',
    NOT_FOUND_REPORT: 'NOT_FOUND_REPORT',
    permission_denied: 'permission_denied',
    not_found: 'not_found',
    conflict: 'conflict',
    bad_request: 'bad_request',
    emergency_maintenance: 'emergency_maintenance',
    unknown_error: 'unknown_error',
  };

  return API.getErrorCode(errorCodeMap, error);
};

type Status = 'idle' | 'loading' | 'succeeded' | 'hasError';

const statusMap: Record<MutationStatus, Status> = {
  idle: 'idle',
  pending: 'loading',
  success: 'succeeded',
  error: 'hasError',
};

type Mutate = (
  workspace: API.Workspace,
  report: API.Report,
  type: API.ReportDownloadType,
) => ReturnType<typeof API.getWorkspacesReportsDownloadUrl>;

type Result = {
  status: Status;
  data?: Awaited<ReturnType<typeof API.getWorkspacesReportsDownloadUrl>>;
  errorCode: ErrorCode;
  mutate: Mutate;
  reset: () => void;
  abort: () => void;
};

export function useGetWorkspacesReportsDownloadUrl(): Result {
  const { getAccessTokenSilently } = useAuth0();

  const abortControllerRef = useRef<AbortController>();

  const result = useMutation<
    Awaited<ReturnType<typeof API.getWorkspacesReportsDownloadUrl>>,
    API.Error,
    {
      workspace: API.Workspace;
      report: API.Report;
      type: API.ReportDownloadType;
    }
  >({
    mutationFn: async ({ workspace, report, type }) => {
      const workspaceId = workspace.workspaceId;
      const reportId = report.reportId;

      const token = await getAccessTokenSilently();

      abortControllerRef.current = new AbortController();

      const res = await API.getWorkspacesReportsDownloadUrl({
        workspaceId,
        reportId,
        type,
        token,
        signal: abortControllerRef.current.signal,
      });

      if (!res.downloadUrl) {
        throw new Error('downloadUrl not found.');
      }

      return res;
    },
  });

  const mutate = async (
    workspace: API.Workspace,
    report: API.Report,
    type: API.ReportDownloadType,
  ) => {
    return result.mutateAsync({ workspace, report, type });
  };

  const abort = () => {
    abortControllerRef.current?.abort();
  };

  return {
    status: statusMap[result.status],
    mutate,
    reset: result.reset,
    data: result.data,
    errorCode: mapErrorCode(result.error),
    abort,
  };
}
