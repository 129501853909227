import { Typography, TypographyProps } from '@mui/material';

type Props = TypographyProps;

export function HeadTitle({ children, sx = [], ...rest }: Props) {
  return (
    <Typography
      pl={1.5}
      fontSize={18}
      fontWeight="bold"
      borderLeft={(props) => `5px solid ${props.palette.nito.main}`}
      sx={[{}, ...(Array.isArray(sx) ? sx : [sx])]}
      {...rest}
    >
      {children}
    </Typography>
  );
}
