import { ButtonProps } from '@mui/material';
import BaseButton from 'src/domains/root/commons/buttons/Base';

type Props = ButtonProps;

export function DialogActionButton({ children, ...rest }: Props) {
  return (
    <BaseButton
      variant="contained"
      sx={{
        px: 3,
        py: 7 / 8,
        backgroundColor: 'primary.main',
        color: 'common.white',
        boxShadow: 'none',

        '&:hover': {
          backgroundColor: 'nito.main',
          color: 'common.white',
          boxShadow: 'none',
        },

        '&:disabled': {
          backgroundColor: 'primary.main',
          color: 'common.white',
          opacity: 0.4,
        },
      }}
      {...rest}
    >
      {children}
    </BaseButton>
  );
}
