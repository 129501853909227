import { Navigate, useNavigate } from 'react-router';
import { useSelector } from 'src/domains/root/store';
import { activeWorkspaceSelector } from 'src/domains/root/features/users/slice';
import { Box, CircularProgress } from '@mui/material';
import useSnackbar from 'src/domains/root/commons/snackbar';
import UnrecoverableError from 'src/domains/root/pages/errors/UnrecoverableError';
import ReportsMakeNew from './ReportsMakeNew';
import { useGetWorkspacesGatewaysSensorDataBeginEndTimestamp } from './useGetWorkspacesGatewaysSensorDataBeginEndTimestamp';
import { usePostWorkspacesReports } from './usePostWorkspacesReports';

export default function ReportsMakeNewContainer() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar('SNACKBAR_REPORT_NEW_SUCCESS', {
    noRefreshButton: true,
    enqueueOptions: {
      variant: 'success',
      autoHideDuration: 3000,
      persist: false,
    },
  });

  const workspace = useSelector(activeWorkspaceSelector);
  const result = useGetWorkspacesGatewaysSensorDataBeginEndTimestamp(
    workspace!,
  );
  const createReport = usePostWorkspacesReports(workspace!, {
    onSuccess: () => {
      navigate('/reports');

      enqueueSnackbar('pages.Reports.New.success');
    },

    nuetralizeResults: () => {
      createReport.reset();
    },
  });

  if (result.status === 'loading') {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="80dvh"
        p={1.25}
      >
        <CircularProgress data-testid="CircularProgress" />
      </Box>
    );
  }

  if (result.status === 'hasError') {
    return (
      <RedirectionForGetWorkspacesGatewaysSensorDataBeginEndTimestampError
        errorCode={result.errorCode}
      />
    );
  }

  if (createReport.status === 'hasError') {
    if (createReport.errorCode !== 'DATETIME_IS_FUTURE') {
      return (
        <RedirectionForPostWorkspacesReportsError
          errorCode={createReport.errorCode}
        />
      );
    }
  }

  const gateways = result.data.gateways;

  return <ReportsMakeNew gateways={gateways} createReport={createReport} />;
}

function RedirectionForGetWorkspacesGatewaysSensorDataBeginEndTimestampError({
  errorCode,
}: {
  errorCode: string;
}) {
  switch (errorCode) {
    case 'not_found':
      return <Navigate to="/errors/workspace-not-found" />;
    case 'permission_denied':
      return <Navigate to="/errors/permission-denied" />;
    case 'emergency_maintenance':
      return <Navigate to="/errors/emergency-maintenance" />;
    case 'unknown_error':
    default:
      return <Navigate to="/errors/unknown-error" />;
  }
}

function RedirectionForPostWorkspacesReportsError({
  errorCode,
}: {
  errorCode: string;
}) {
  switch (errorCode) {
    case 'not_found':
      return <Navigate to={PostWorkspacesReportsErrorPageRoute} />;
    case 'permission_denied':
      return <Navigate to="/errors/permission-denied" />;
    case 'emergency_maintenance':
      return <Navigate to="/errors/emergency-maintenance" />;
    case 'unknown_error':
    default:
      return <Navigate to={PostWorkspacesReportsErrorPageRoute} />;
  }
}

export function PostWorkspacesReportsErrorPage() {
  return <UnrecoverableError messageId="pages.Reports.New.failPage" />;
}

export const PostWorkspacesReportsErrorPageRoute = '/errors/reports/retry';
