import { useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { HeadTitle } from 'src/shared/ui';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActionsV2,
  DialogActionButton,
  CancelButton,
  DialogTitleAlert,
} from 'src/domains/root/commons/dialog';
import * as API from 'src/apis';
import { useGetWorkspacesReportsDownloadUrl } from './useGetWorkspacesReportsDownloadUrl';

interface ReportsDownloadDialogProps {
  report: API.Report | null;
  reportsDownloadUrl: ReturnType<typeof useGetWorkspacesReportsDownloadUrl>;
  open: boolean;
  onClose: () => void;
  onDownload: (
    report: API.Report,
    type: API.ReportDownloadType,
  ) => Promise<void>;
}

export default function ReportsDownloadDialog(
  props: ReportsDownloadDialogProps,
) {
  const { report, reportsDownloadUrl, open, onClose, onDownload } = props;

  const intl = useIntl();

  const isLoading = reportsDownloadUrl.status === 'loading';

  const handleAction = () => {
    if (!report) {
      return;
    }

    onDownload(report, 'csv');
  };

  useEffect(() => {
    return () => {
      reportsDownloadUrl.abort();
      reportsDownloadUrl.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog loggingId="ReportsDownloadDialog" open={open} fullWidth>
      <DialogTitle onClose={onClose}>
        {intl.formatMessage({
          id: 'pages.Reports.downloadDialog.title',
        })}
      </DialogTitle>
      <DialogTitleAlert
        status={reportsDownloadUrl.status}
        message={{
          success: intl.formatMessage({
            id: 'pages.Reports.reportListTable.csvDownloadSuccess',
          }),
          error: intl.formatMessage(
            {
              id: `pages.Reports.reportListTable.csvDownloadFailed.content.${reportsDownloadUrl.errorCode}`,
            },
            {
              br: <br />,
            },
          ),
        }}
      />
      <DialogContent>
        <HeadTitle fontSize={16}>
          {intl.formatMessage({
            id: 'pages.Reports.downloadDialog.headTitle',
          })}
        </HeadTitle>
        <Box pl={2} pt={2}>
          <Box
            display="flex"
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <Typography>
              {intl.formatMessage({
                id: 'pages.Reports.downloadDialog.csv.label',
              })}
            </Typography>
            <Typography fontSize={12}>
              {intl.formatMessage({
                id: 'pages.Reports.downloadDialog.csv.description',
              })}
            </Typography>
          </Box>
        </Box>
        <Box
          display={isLoading ? 'flex' : 'none'}
          flexDirection="column"
          alignItems="center"
          pt={3}
        >
          <Box pb={1}>
            <CircularProgress size={24} />
          </Box>
          <Typography>
            {intl.formatMessage({
              id: 'pages.Reports.downloadDialog.loading',
            })}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActionsV2>
        <CancelButton onClick={onClose} />
        <DialogActionButton onClick={handleAction} disabled={isLoading}>
          {intl.formatMessage({
            id: 'pages.Reports.downloadDialog.actionButton',
          })}
        </DialogActionButton>
      </DialogActionsV2>
    </Dialog>
  );
}
