import { Box } from '@mui/material';
import { useRef, useState } from 'react';
import * as API from 'src/apis';
import { type Period } from './PeriodInput';
import { useGetWorkspacesReportsDownloadUrl } from './useGetWorkspacesReportsDownloadUrl';
import { ReportListTable } from './ReportListTable';
import { ReportListSearchCondition } from './ReportListSearchCondition';
import { useDeleteWorkspacesReports } from './useDeleteWorkspacesReports';

export const GATEWAYS_ALL = '__GATEWAYS_ALL__';

interface ReportListProps {
  reports: API.Report[];
  reportsDownloadUrl: ReturnType<typeof useGetWorkspacesReportsDownloadUrl>;
  reportsDelete: ReturnType<typeof useDeleteWorkspacesReports>;
  onRequestReportDownloadUrl: (
    report: API.Report,
    type: API.ReportDownloadType,
  ) => Promise<void>;
}

export default function ReportList({
  reports,
  reportsDownloadUrl,
  reportsDelete,
  onRequestReportDownloadUrl,
}: ReportListProps) {
  const initialPeriod = reports.length
    ? reports.reduce(
        (acc, cur) => ({
          beginAt: Math.min(acc.beginAt, cur.beginAt),
          endAt: Math.max(acc.endAt, cur.endAt),
        }),
        {
          beginAt: reports[0].beginAt,
          endAt: reports[0].endAt,
        },
      )
    : { beginAt: 0, endAt: 0 };
  const initialGatewayNames = Array.from(
    new Set(reports.map((report) => report.gatewayName)),
  );
  const initialGatewayNamesRef = useRef(initialGatewayNames);

  const [period, setPeriod] = useState({
    beginAt: initialPeriod.beginAt,
    endAt: initialPeriod.endAt,
  });
  const [selectedGatewayName, setSelectedGatewayName] =
    useState<string>(GATEWAYS_ALL);
  const [searchTitle, setSearchTitle] = useState('');
  const [isPeriodDirty, setIsPeriodDirty] = useState(false);

  const isSearchDisabledByNoReport = reports.length === 0;

  const isClearButtonEnabled =
    period.beginAt !== initialPeriod.beginAt ||
    isPeriodDirty !== false ||
    period.endAt !== initialPeriod.endAt ||
    selectedGatewayName !== GATEWAYS_ALL ||
    searchTitle !== '';

  const handlePeriodChange = ({ beginAt, endAt }: Period) => {
    setPeriod({ beginAt, endAt });
  };

  const handleChangeGatewayName = (gatewayName: string) => {
    setSelectedGatewayName(gatewayName);
  };

  const handleChangeSearchTitle = (title: string) => {
    setSearchTitle(title);
  };

  const handleCheckPeriodDirty = () => {
    setIsPeriodDirty(true);
  };

  const handleClearSearchCondition = () => {
    setSelectedGatewayName(GATEWAYS_ALL);
    setSearchTitle('');
    setPeriod({
      beginAt: initialPeriod.beginAt,
      endAt: initialPeriod.endAt,
    });
    setIsPeriodDirty(false);
  };

  return (
    <Box display="flex" flexDirection="column" rowGap={5}>
      <ReportListSearchCondition
        isSearchDisabledByNoReport={isSearchDisabledByNoReport}
        initialPeriod={initialPeriod}
        period={period}
        isPeriodDirty={isPeriodDirty}
        checkPeriodDirty={handleCheckPeriodDirty}
        handlePeriodChange={handlePeriodChange}
        initialGatewayNames={initialGatewayNamesRef.current}
        selectedGatewayName={selectedGatewayName}
        changeGatewayName={handleChangeGatewayName}
        searchTitle={searchTitle}
        changeSearchTitle={handleChangeSearchTitle}
        isClearButtonEnabled={isClearButtonEnabled}
        clearSearchCondition={handleClearSearchCondition}
      />
      <ReportListTable
        reports={reports}
        reportsDownloadUrl={reportsDownloadUrl}
        reportsDelete={reportsDelete}
        period={period}
        selectedGatewayName={selectedGatewayName}
        searchTitle={searchTitle}
        onRequestReportDownloadUrl={onRequestReportDownloadUrl}
      />
    </Box>
  );
}
