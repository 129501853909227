import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useState, ChangeEvent, useEffect } from 'react';
import { useIntl } from 'react-intl';
import BaseButton from 'src/domains/root/commons/buttons/Base';
import NegativeButton from 'src/domains/root/commons/buttons/Negative';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'src/domains/root/commons/dialog';
import { toYmd, fromUnixMs } from 'src/utils/time';
import { Period } from './PeriodInput';

interface PeriodDialogProps {
  open: boolean;
  title: string;
  content: React.ReactNode;
  closeText?: string;
  confrimText: string;
  loggingId: string;
  initialPeriod: Period;
  period: Period;
  isLimited?: boolean;
  onConfirm: (props: Period) => void;
  onDirty: () => void;
  close: () => void;
}

export default function PeriodDialog(props: PeriodDialogProps) {
  const {
    open,
    title,
    content,
    closeText,
    confrimText,
    loggingId,
    initialPeriod,
    period,
    isLimited,
    onConfirm,
    onDirty,
    close,
  } = props;

  const intl = useIntl();

  const [localPeriod, setLocalPeriod] = useState(period);

  const min = toYmd(fromUnixMs(initialPeriod.beginAt));
  const max = toYmd(fromUnixMs(initialPeriod.endAt));

  const beginDate = toYmd(fromUnixMs(localPeriod.beginAt));
  const endDate = toYmd(fromUnixMs(localPeriod.endAt));

  const handleBeginAtChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newBeginAt = new Date(event.target.value || min).getTime();

    setLocalPeriod((prev) => ({
      ...prev,
      beginAt: newBeginAt,
    }));
  };

  const handleEndAtChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newEndAt = new Date(event.target.value || max).getTime();

    setLocalPeriod((prev) => ({
      ...prev,
      endAt: newEndAt,
    }));
  };

  const handleConfirm = () => {
    onConfirm({ beginAt: localPeriod.beginAt, endAt: localPeriod.endAt });
    onDirty();
    close();
  };

  useEffect(() => {
    setLocalPeriod(period);
  }, [period]);

  return (
    <Dialog open={open} fullWidth maxWidth="sm" loggingId={loggingId}>
      <DialogTitle onClose={close}>{title}</DialogTitle>
      <DialogContent sx={{ py: 3 }}>
        {content}
        <Box
          pt={3.5}
          display="grid"
          gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}
          gap={3}
        >
          <TextField
            type="date"
            name="begin"
            value={beginDate}
            onChange={handleBeginAtChange}
            size="small"
            label={intl.formatMessage({
              id: 'pages.Reports.searchCondition.periodDialog.start',
            })}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                min: isLimited ? min : undefined,
                max: endDate,
              },
              sx: { pr: 1 },
            }}
          />
          <TextField
            type="date"
            name="end"
            value={endDate}
            onChange={handleEndAtChange}
            size="small"
            label={intl.formatMessage({
              id: 'pages.Reports.searchCondition.periodDialog.end',
            })}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                min: beginDate,
                max,
              },
              sx: { pr: 1 },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          p: 3.5,
          flexDirection: {
            xs: 'column-reverse',
            sm: 'row',
          },
          gap: 2,
          '& > :not(:first-of-type)': {
            ml: 0,
          },
        }}
      >
        <NegativeButton
          onClick={close}
          sx={{ width: { xs: '100%', sm: 'unset' } }}
        >
          {closeText ?? intl.formatMessage({ id: 'common.cancel' })}
        </NegativeButton>
        <BaseButton
          variant="contained"
          onClick={handleConfirm}
          sx={{
            px: 3,
            py: 7 / 8,
            backgroundColor: 'primary.main',
            color: 'common.white',
            boxShadow: 'none',
            width: { xs: '100%', sm: 'unset' },
            minWidth: 'unset',

            '&:hover': {
              backgroundColor: 'nito.main',
              color: 'common.white',
              boxShadow: 'none',
            },

            '&:disabled': {
              backgroundColor: 'primary.main',
              color: 'common.white',
            },
          }}
        >
          {confrimText}
        </BaseButton>
      </DialogActions>
    </Dialog>
  );
}
