import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActionsV2,
  DialogActionButton,
  CancelButton,
  DialogTitleAlert,
} from 'src/domains/root/commons/dialog';
import * as API from 'src/apis';
import LoadingOverlay from 'src/domains/root/commons/LoadingOverlay';
import { useDeleteWorkspacesReports } from './useDeleteWorkspacesReports';

interface ReportsDeleteDialogProps {
  report: API.Report | null;
  reportsDelete: ReturnType<typeof useDeleteWorkspacesReports>;
  open: boolean;
  onClose: () => void;
}

export default function ReportsDeleteDialog(props: ReportsDeleteDialogProps) {
  const { report, reportsDelete, open, onClose } = props;

  const intl = useIntl();

  const isLoading = reportsDelete.status === 'loading';

  const handleAction = async () => {
    if (!report) {
      return;
    }

    await reportsDelete.mutate(report);

    onClose();
  };

  useEffect(() => {
    return () => {
      reportsDelete.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog loggingId="ReportsDeleteDialog" open={open} fullWidth>
      <LoadingOverlay open={isLoading} />
      <DialogTitle onClose={onClose}>
        {intl.formatMessage({
          id: 'pages.Reports.deleteDialog.title',
        })}
      </DialogTitle>
      <DialogTitleAlert
        status={reportsDelete.status}
        message={{
          error: intl.formatMessage({
            id: `pages.Reports.deleteDialog.failed.${reportsDelete.errorCode}`,
          }),
        }}
      />
      <DialogContent>
        <Typography>
          {intl.formatMessage({
            id: 'pages.Reports.deleteDialog.content1',
          })}
        </Typography>
        <Typography>
          {intl.formatMessage({
            id: 'pages.Reports.deleteDialog.content2',
          })}
        </Typography>
        <Box pt={2} fontWeight={700} display="flex">
          <Typography fontWeight="inherit" flex="none">
            {intl.formatMessage({
              id: 'pages.Reports.deleteDialog.reportTitle',
            })}
          </Typography>
          <Typography fontWeight="inherit">{report?.title}</Typography>
        </Box>
      </DialogContent>
      <DialogActionsV2>
        <CancelButton onClick={onClose} />
        <DialogActionButton onClick={handleAction} disabled={isLoading}>
          {intl.formatMessage({
            id: 'pages.Reports.deleteDialog.actionButton',
          })}
        </DialogActionButton>
      </DialogActionsV2>
    </Dialog>
  );
}
