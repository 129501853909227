import { API_ENDPOINT } from 'src/constants';
import axios from 'src/utils/api';
import { handleError } from './error';

type Props = {
  workspaceId: string;
  title: string;
  beginAt: string;
  endAt: string;
  gatewayIds: string[];
  token: string;
};

export async function postWorkspacesReports({
  workspaceId,
  title,
  beginAt,
  endAt,
  gatewayIds,
  token,
}: Props): Promise<void> {
  return axios
    .post(
      `${API_ENDPOINT}/workspaces/${workspaceId}/reports`,
      { title, beginAt, endAt, gatewayIds },
      { headers: { Authorization: `Bearer ${token}` } },
    )
    .then((res) => res.data)
    .catch((error) => {
      throw handleError(error);
    });
}
