import { useSelector } from 'src/domains/root/store';
import { activeWorkspaceSelector } from 'src/domains/root/features/users/slice';
import { Box, CircularProgress } from '@mui/material';
import { Navigate } from 'react-router';
import * as API from 'src/apis';
import { downloadFile } from 'src/utils/file';
import useCabiotSnackbar from 'src/domains/root/commons/snackbar';
import { useQueryClient } from '@tanstack/react-query';
import ReportList from './ReportList';
import { useGetWorkspacesReports } from './useGetWorkspacesReports';
import { useGetWorkspacesReportsDownloadUrl } from './useGetWorkspacesReportsDownloadUrl';
import { useDeleteWorkspacesReports } from './useDeleteWorkspacesReports';

export default function ReportListContainer() {
  const workspace = useSelector(activeWorkspaceSelector);
  const result = useGetWorkspacesReports(workspace!);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useCabiotSnackbar();

  const reportsDownloadUrl = useGetWorkspacesReportsDownloadUrl();
  const reportsDelete = useDeleteWorkspacesReports(workspace!, {
    onSuccess: (_, { report }) => {
      queryClient.setQueryData(result.keys, (old: API.Reports) => ({
        ...old,
        reports: old.reports.filter(
          (item) => item.reportId !== report.reportId,
        ),
      }));

      enqueueSnackbar('pages.Reports.deleteDialog.success', 'success');
    },
  });

  const handleRequestReportDownloadUrl = async (
    report: API.Report,
    type: API.ReportDownloadType,
  ) => {
    if (!workspace) {
      return;
    }

    const status = reportsDownloadUrl.status;

    if (status === 'loading') {
      return;
    }

    const res = await reportsDownloadUrl.mutate(workspace, report, type);

    downloadFile({ fileURL: res.downloadUrl });
  };

  if (result.status === 'loading') {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="80dvh"
        p={1.25}
      >
        <CircularProgress data-testid="CircularProgress" />
      </Box>
    );
  }

  if (result.status === 'hasError') {
    return (
      <RedirectionForGetWorkspacesReprotsError errorCode={result.errorCode} />
    );
  }

  if (
    reportsDownloadUrl.status === 'hasError' &&
    reportsDownloadUrl.errorCode !== 'NOT_FOUND_REPORT' &&
    reportsDownloadUrl.errorCode !== 'conflict' &&
    reportsDownloadUrl.errorCode !== 'unknown_error'
  ) {
    return (
      <RedirectionForGetWorkspacesReprotsDownloadUrl
        errorCode={reportsDownloadUrl.errorCode}
      />
    );
  }

  if (
    reportsDelete.status === 'hasError' &&
    reportsDelete.errorCode !== 'NOT_FOUND_REPORT' &&
    reportsDelete.errorCode !== 'unknown_error'
  ) {
    return (
      <RedirectionForDeleteWorkspacesReprots
        errorCode={reportsDelete.errorCode}
      />
    );
  }

  const reports = result.data.reports;

  return (
    <ReportList
      reports={reports}
      reportsDownloadUrl={reportsDownloadUrl}
      reportsDelete={reportsDelete}
      onRequestReportDownloadUrl={handleRequestReportDownloadUrl}
    />
  );
}

function RedirectionForGetWorkspacesReprotsError({
  errorCode,
}: {
  errorCode: string;
}) {
  switch (errorCode) {
    case 'not_found':
      return <Navigate to="/errors/workspace-not-found" />;
    case 'permission_denied':
      return <Navigate to="/errors/permission-denied" />;
    case 'emergency_maintenance':
      return <Navigate to="/errors/emergency-maintenance" />;
    case 'unknown_error':
    default:
      return <Navigate to="/errors/unknown-error" />;
  }
}

function RedirectionForGetWorkspacesReprotsDownloadUrl({
  errorCode,
}: {
  errorCode: string;
}) {
  switch (errorCode) {
    case 'not_found':
      return <Navigate to="/errors/workspace-not-found" />;
    case 'permission_denied':
    case 'NOT_JOINED_WORKSPACE':
      return <Navigate to="/errors/permission-denied" />;
    case 'emergency_maintenance':
      return <Navigate to="/errors/emergency-maintenance" />;
    default:
      return <Navigate to="/errors/unknown-error" />;
  }
}

function RedirectionForDeleteWorkspacesReprots({
  errorCode,
}: {
  errorCode: string;
}) {
  switch (errorCode) {
    case 'not_found':
      return <Navigate to="/errors/workspace-not-found" />;
    case 'permission_denied':
    case 'NOT_JOINED_WORKSPACE':
      return <Navigate to="/errors/permission-denied" />;
    case 'emergency_maintenance':
      return <Navigate to="/errors/emergency-maintenance" />;
    default:
      return <Navigate to="/errors/unknown-error" />;
  }
}
