import {
  DialogActions as _DialogActions,
  DialogActionsProps,
} from '@mui/material';

/**
 * @deprecated use DialogActionsV2
 * */
export default function DialogActions({
  children,
  sx = [],
  ...rest
}: DialogActionsProps) {
  return (
    <_DialogActions
      sx={[
        {
          justifyContent: {
            xs: 'center',
            sm: 'flex-end',
          },
          p: (theme) => `${theme.spacing(3.5)} ${theme.spacing(2.5)}`,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {children}
    </_DialogActions>
  );
}

export function DialogActionsV2({
  children,
  sx = [],
  ...rest
}: DialogActionsProps) {
  return (
    <_DialogActions
      sx={[
        {
          flexDirection: {
            xs: 'column-reverse',
            sm: 'row',
          },
          p: 3,
          gap: 2,

          '& > :not(:first-of-type)': {
            ml: 0,
          },

          '& > *': {
            width: { xs: '100%', sm: 'unset' },
          },
        },

        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {children}
    </_DialogActions>
  );
}
